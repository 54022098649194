import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import Turbolinks from 'turbolinks'
import 'alpinejs'
import 'typeface-josefin-sans'
import '@fontsource/montserrat'
import '../controllers'
require.context('../images', true)
import '../css/application.scss'

Rails.start()
ActiveStorage.start()
Turbolinks.start()
