import { Controller } from 'stimulus'

import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      autoplay: {
        delay: 8000,
      },
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
      },
    })
  }
}
